import React, {useEffect, useState} from 'react';
import {
  Box,
  TextField,
  Modal,
  Button,
  Select,
  FormControl,
  FormHelperText,
  Typography,
  MenuItem,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import {useStyles} from './styles';
import {useForm, Controller} from 'react-hook-form';
import {inviteUserForm} from './types';
import {useParams} from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import API, {authorizationToken} from 'adapters/rest';
import CircularProgress from '@material-ui/core/CircularProgress';
import useUserData from 'modules/useUserData';

export default function InviteUserModal({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (key: boolean) => void;
}) {
  const classes = useStyles();
  const {projectId} = useParams<{projectId: string}>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {accessToken} = useUserData();
  const [responseMessage, setResponseMessage] = useState<string>('');

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    register,
    formState: {errors},
  } = useForm<inviteUserForm>({
    mode: 'onChange',
    defaultValues: {
      redirecturi: ``,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      isPasswordTemporary: false,
    },
  });

  const onSubmit = (newData: inviteUserForm) => {
    setIsLoading(true);
    const data = {...newData, redirecturi: `/${projectId}`};
    const url = `/iam/v1/applications/${projectId}/users`;
    authorizationToken.set(accessToken);
    API.post(url, {...data, system: 'kuido'})
      .then(response => {
        // console.log('inviteUser Response', response.data.message);
        setIsLoading(false);
        setResponseMessage('User Invited Successfully');
      })
      .catch(error => {
        setIsLoading(false);
        setResponseMessage('Something went wrong');
      });
  };

  const {password, isPasswordTemporary} = watch();

  useEffect(() => {
    register('isPasswordTemporary');
  }, []);

  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      onClose={() => (isLoading ? {} : setOpen(false))}
      open={open}
    >
      <Box className={classes.modalContainer}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => (isLoading ? {} : setOpen(false))}
        />

        {isLoading ? (
          <Box className={classes.messageContainer}>
            <Typography className={classes.title} style={{marginBottom: 20}}>
              Inviting your user
            </Typography>
            <CircularProgress />
          </Box>
        ) : responseMessage === '' ? (
          <>
            <Box display="flex" flexDirection="column" mb={4} alignItems="center">
              <Typography className={classes.title}>Invite User</Typography>
              <Typography className={classes.subtitle}>
                Provide data with this form to invite user to your app.
              </Typography>
            </Box>

            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
              <Controller
                render={field => (
                  <TextField
                    type="email"
                    autoComplete="email"
                    style={{marginBottom: '20px'}}
                    {...field}
                    variant="outlined"
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                    placeholder="Enter user email"
                    inputProps={{type: 'email'}}
                    size="small"
                  />
                )}
                name="email"
                control={control}
                rules={{
                  required: 'User email is required',
                }}
              />

              <Controller
                render={field => (
                  <TextField
                    variant="outlined"
                    {...field}
                    placeholder="Enter first name"
                    size="small"
                    className={classes.textInputs}
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName?.message}
                  />
                )}
                name="firstName"
                control={control}
                rules={{
                  required: 'First name is required',
                  minLength: {value: 3, message: 'minimum 3 characters'},
                  maxLength: {value: 32, message: 'maximum 32 characters'},
                }}
              />

              <Controller
                render={field => (
                  <TextField
                    variant="outlined"
                    {...field}
                    placeholder="Enter last name"
                    size="small"
                    className={classes.textInputs}
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName?.message}
                  />
                )}
                name="lastName"
                control={control}
                rules={{
                  required: 'Last name is required',
                  minLength: {value: 3, message: 'minimum 3 characters'},
                  maxLength: {value: 32, message: 'maximum 32 characters'},
                }}
              />

              <Controller
                render={field => (
                  <TextField
                    type={'password'}
                    variant="outlined"
                    {...field}
                    placeholder="Enter password"
                    size="small"
                    className={classes.textInputs}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                    inputProps={{
                      maxLength: 16,
                    }}
                  />
                )}
                name="password"
                control={control}
                rules={{
                  required: 'Password is required',
                  minLength: {value: 3, message: 'minimum 3 characters'},
                  maxLength: {value: 32, message: 'maximum 32 characters'},
                }}
              />
              <Controller
                render={field => (
                  <TextField
                    type={'password'}
                    variant="outlined"
                    {...field}
                    placeholder="Confirm password"
                    size="small"
                    className={classes.textInputs}
                    error={Boolean(errors.confirmPassword)}
                    helperText={errors.confirmPassword?.message}
                    inputProps={{
                      maxLength: 16,
                    }}
                  />
                )}
                name="confirmPassword"
                control={control}
                rules={{
                  required: 'Confirm password is required',
                  validate: (val: string) => {
                    if (password !== val) {
                      return 'Your passwords do not match';
                    }
                  },
                }}
              />
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      name={'isPasswordTemporary'}
                      defaultChecked={isPasswordTemporary}
                      color="primary"
                      onChange={event => {
                        const {checked} = event.target;
                        setValue('isPasswordTemporary', checked);
                      }}
                    />
                  }
                  label="Password is temporary"
                />
              </FormControl>
              <Controller
                render={field => (
                  <FormControl>
                    <Select
                      {...field}
                      displayEmpty
                      variant="outlined"
                      className={classes.selectInput}
                      renderValue={selected => {
                        if (!selected) {
                          return <Typography> User role</Typography>;
                        }
                        return <>{selected}</>;
                      }}
                    >
                      <MenuItem value="WORKER">Worker</MenuItem>
                      <MenuItem value="VIEWER">Viewer</MenuItem>
                      <MenuItem value="ADMIN">Admin</MenuItem>
                    </Select>
                    <FormHelperText error={Boolean(errors.userRole)}>
                      {errors.userRole ? 'User Role is Required !' : ''}
                    </FormHelperText>
                  </FormControl>
                )}
                name="userRole"
                control={control}
                defaultValue=""
                rules={{
                  required: 'User Role is required',
                }}
              />

              <Box display="flex" mt={5} justifyContent="center">
                <Button variant="contained" color="primary" type="submit" style={{width: '70%'}}>
                  Invite
                </Button>
              </Box>
            </form>
          </>
        ) : (
          <Box className={classes.messageContainer}>
            <Typography className={classes.title} style={{marginBottom: 20}}>
              {responseMessage}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{width: '50%'}}
              onClick={() => {
                setOpen(false);
                setResponseMessage('');
              }}
            >
              Close
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
