import React, { useCallback, useState, useEffect } from "react";
import { Box,TextField, Button } from "@material-ui/core";
import { useStyles } from "../styles";
import { useForm, Controller } from "react-hook-form";
import API, { authorizationToken } from "adapters/rest";
import useUserData from "modules/useUserData";
import debounce from "lodash.debounce";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FirstTabData, ApplicationData } from "../../types";

export default function FirstTab({
  setValue,
  data,
  setData,
}: {
  setValue: (key: number) => void;
  data: ApplicationData;
  setData: (data: ApplicationData) => void;
}) {

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
    trigger,
  } = useForm<FirstTabData>({
    mode: "onChange",
    defaultValues: {
      name: data.name || "",
      description: data.description || "",
    },
  });

  const { accessToken } = useUserData();
  const classes = useStyles();
  const { name } = watch();
  const [isValidName, setIsValidName] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = (newData: FirstTabData) => {
    setData({ ...data, ...newData });
    setValue(2);
  };

  const triggerValidation = async () => {
    if (name !== "") {
      const isValid = await trigger("name");
      if (isValid) {
        setIsLoading(true);
        validateNameFromApi(name);
      }
    }
  };

  useEffect(() => {
    triggerValidation();
  }, [name]);

  const validateNameFromApi = useCallback(
    debounce((name: string) => {
      const url = `/iam/v1/applications/${name}/validated`;
      authorizationToken.set(accessToken);
      API.get(url).then((response) => {
        setIsValidName(Boolean(response.data.result));
        setIsLoading(false);
      });
    }, 1000),
    []
  );

  return (
    <Box className={classes.tabContentContaner}>
      {/* Tab content */}
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Controller
          render={(field) => (
            <TextField
              style={{ marginBottom: "20px" }}
              {...field}
              variant="outlined"
              error={Boolean(errors.name) || !isValidName}
              helperText={
                errors.name
                  ? errors.name.message
                  : isValidName
                  ? ""
                  : "Application name already exists"
              }
              placeholder="Application name"
              InputProps={{
                endAdornment: isLoading ? (
                  <CircularProgress color="primary" />
                ) : (
                  <></>
                ),
              }}
            />
          )}
          name="name"
          control={control}
          defaultValue="Mohamed" 
          rules={{
            required: "Application name is required",
            minLength: { value: 3, message: "minimum 3 characters" },
            maxLength: { value: 32, message: "maximum 32 characters" },
          }}
        />

        <Controller
          render={(field) => (
            <TextField
              variant="outlined"
              {...field}
              multiline
              rows={2}
              placeholder="Application description"
            />
          )}
          name="description"
          control={control}
        />

        <Box display="flex" mt={8} justifyContent="space-between">
          <Button variant="outlined" disabled>
            previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
          >
            Next
          </Button>
        </Box>
      </form>
    </Box>
  );
}
