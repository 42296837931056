import React from 'react';
import clsx from 'clsx';
import SideMenu from './SideMenu/';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import Notifications from './Notifications';
import {useTranslation} from 'react-i18next';

export const useStyles = makeStyles(theme => ({
  mainContent: {
    paddingLeft: theme.direction === 'ltr' ? 70 : theme.spacing(4),
    paddingTop: 0,
    paddingBottom: theme.spacing(4),
    paddingRight: theme.direction === 'ltr' ? theme.spacing(4) : 70,
    height: '100vh',
    '&.white': {
      minHeight: '100vh',
      background: theme.palette.common.white,
    },
  },
}));

type MenuItem = {
  icon: JSX.Element;
  text: string;
  path: string;
};

interface Props {
  children: React.ReactNode;
  sideMenuItems?: MenuItem[];
  sideMenuBottomItems?: MenuItem[];
  showProjectsDropdown?: boolean;
  background?: string;
}

export default function Layout(props: Props) {
  const {
    children,
    sideMenuItems,
    sideMenuBottomItems,
    showProjectsDropdown = false,
    background,
  } = props;
  const isSideMenuVisible = Boolean(sideMenuItems?.length || sideMenuBottomItems?.length);
  const classes = useStyles(isSideMenuVisible);
  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false);
  const {i18n} = useTranslation();

  return (
    <Box position={'relative'} dir={i18n.dir(i18n.language)}>
      {isSideMenuVisible && (
        <>
          <SideMenu
            modulesList={sideMenuItems || []}
            menuIsOpen={menuIsOpen}
            setMenuIsOpen={setMenuIsOpen}
            sideMenuBottomItems={sideMenuBottomItems || []}
          />
        </>
      )}
      {/* <Notifications /> */}
      <main className={clsx(classes.mainContent, background)}>{children}</main>
    </Box>
  );
}
